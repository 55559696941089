import { Checkbox, CheckboxProps } from "@mui/material";
import { FieldInputProps } from "formik";
import { Colors } from "../util/Colors";

type Props = CheckboxProps & {
    field?: FieldInputProps<boolean>;
    isTouched?: boolean;
    label?: string;
    isError?: boolean;
};

export const CustomCheckbox = ({ label, field, onChange, indeterminate, isError, ...props }: Props) => {
    return (
        <div style={{ marginLeft: "none", display: "flex" }}>
            <Checkbox
                value={field?.value}
                name={field?.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    field?.onChange(event);
                    onChange?.(event, checked);
                }}
                onBlur={field?.onBlur}
                aria-label={props["aria-label"]}
                disableRipple
                checked={!!field?.value}
                indeterminate={indeterminate}
                sx={{
                    "& .MuiSvgIcon-root": {
                        color: isError ? Colors.RED : undefined,
                    },
                }}
                {...props}
            />
            {label}
        </div>
    );
};
