import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { AccountType, BaseCMSCustomer, PostUserRequestsPayloadV2 } from "../../../network/APITypes";
import { FieldError } from "../../ui/FieldError";
import { Colors } from "../../util/Colors";
import { getAccountTypeText } from "../../util/Helpers";
import { getUserPhoneAndEmailValidationSchema } from "../editUser/EditUserForm";
import { PartnerPortalCustomerNumberSelection } from "./AddPartnerPortalCustomerNumberSelection";
import { PartnerPortalUserDetailsFields } from "./PartnerPortalUserDetailsFields";
import { SelectRoleField } from "./SelectRoleField";
import { useRoles } from "../../../hooks/useRoles";
import { useInvitationTypeSelection } from "../../../hooks/useInvitationTypeSelection";
import { createUser, getUserRequestInvitationType, InviteButton, sanitizeUserData } from "./AddUserForm";

type AddUserFormProps = {
    accountType: AccountType;
    onClose: () => void;
    onSubmit?: () => void | Promise<void>;
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
};
type MultiFormStepConfig = {
    name: string;
    title?: string;
    step: string;
    validationSchema?: any;
    content?: React.ReactNode;
    autoOverflow?: boolean;
};

const getPartnerPortalUserValidationSchema = () => {
    const { email, phone } = getUserPhoneAndEmailValidationSchema();
    return Yup.object().shape({
        corporatePositionID: Yup.string().required(t("validationError.corporatePosition")),
        email,
        firstName: Yup.string().required(t("validationError.firstName")),
        lastName: Yup.string().required(t("validationError.lastName")),
        phone,
        salutation: Yup.string().required(t("validationError.title")),
    });
};

const getPages = ({
    onSelectCustomer,
    accountType,
}: {
    onSelectCustomer: (customer?: BaseCMSCustomer) => void;
    accountType: AccountType;
}): MultiFormStepConfig[] => [
    { step: t("common.accountType.select"), name: "accountType" },
    {
        autoOverflow: true,
        content: <PartnerPortalCustomerNumberSelection onSelectCustomer={onSelectCustomer} accountType={accountType} />,
        name: "customer",
        step: t("addUserForm.step.customerNumber.description"),
        validationSchema: Yup.object().shape({
            customerID: Yup.string().required(t("validationError.customerNumber")),
        }),
    },
    {
        autoOverflow: true,
        content: <PartnerPortalUserDetailsFields />,
        name: "details",
        step: t("addUserForm.step.userData.description"),
        validationSchema: getPartnerPortalUserValidationSchema,
    },
    {
        autoOverflow: true,
        content: <SelectRoleField />,
        name: "role",
        step: t("screen.userDetails.select.role.label"),
        validationSchema: Yup.object().shape({
            roleId: Yup.string().required(t("validationError.role")),
        }),
    },
];

export const AddPartnerPortalUserForm = observer(
    ({ accountType, onClose, onSubmit, step, setStep }: AddUserFormProps) => {
        const [selectedCustomer, setSelectedCustomer] = useState<BaseCMSCustomer | undefined>();
        const roles = useRoles();
        const [inviteUser, setInviteUser] = useState(false);
        const [userPayload, setUserPayload] = useState<PostUserRequestsPayloadV2>();

        const getInitialAccountType = useCallback(() => accountType as AccountType, [accountType]);
        const getInitialRoleId = useCallback(() => {
            return roles.rolesResponse?.roles?.find((role) =>
                role.isDefaultForAccountTypeCustomerTypeCombinations.find(
                    (combination) =>
                        combination.accountType === accountType && combination.customerType === accountType,
                ),
            )?.id;
        }, [accountType, roles.rolesResponse?.roles]);
        const initialValues: PostUserRequestsPayloadV2 = useMemo(() => {
            return {
                accountType: getInitialAccountType(),
                authenticationTargetIdentifier: "PP",
                corporatePositionID: "",
                customerID: "",
                email: "",
                firstName: "",
                lastName: "",
                roleId: getInitialRoleId(),
                salutation: "",
            };
        }, [getInitialAccountType, getInitialRoleId]);

        const handleClickNext = (
            model: PostUserRequestsPayloadV2,
            helpers: FormikHelpers<PostUserRequestsPayloadV2>,
        ) => {
            helpers.setTouched({});
            helpers.setSubmitting(false);

            setStep((prevActiveStep) => prevActiveStep + 1);
        };

        const handleUserCreated = async () => {
            await onSubmit?.();
            onClose();
        };
        const inviteDialog = useInvitationTypeSelection({
            hideBackdrop: true,
            onClose,
            userPayload,
            onUserCreated: handleUserCreated,
        });

        const handleSubmit = async (model: PostUserRequestsPayloadV2) => {
            const userData = cloneDeep(model);

            sanitizeUserData(userData, accountType);

            const inviteSelection = inviteUser && userData.email && userData.phone;

            if (inviteSelection) {
                setUserPayload(userData as PostUserRequestsPayloadV2);
                inviteDialog.open();
                return;
            }

            await createUser(
                userData,
                inviteUser,
                getUserRequestInvitationType(userData, inviteUser),
                handleUserCreated,
            );
        };

        const handleSelectCustomer = useCallback((customer?: BaseCMSCustomer) => {
            setSelectedCustomer(customer);
        }, []);

        const getOptionalStepTitle = (index: number) => {
            if (index === 0) {
                return `${getAccountTypeText(accountType)}`;
            }

            if (index === 1) {
                return selectedCustomer?.externalId;
            }
        };

        const pages = getPages({
            onSelectCustomer: handleSelectCustomer,
            accountType,
        });

        const { content, validationSchema } = pages[step];

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    onSubmit={step < pages.length - 1 ? handleClickNext : handleSubmit}
                    validationSchema={validationSchema}
                    validateOnChange
                    enableReinitialize
                >
                    {({ errors, isSubmitting, touched, submitForm, values }) => (
                        <Form
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                overflow: "hidden",
                                height: "100%",
                            }}
                            noValidate
                        >
                            <Stepper activeStep={step} orientation="vertical">
                                {pages.map((page, index) => (
                                    <Step key={page.step}>
                                        <StepLabel
                                            optional={
                                                index < step ? (
                                                    <p style={{ color: Colors.GREY_500, fontSize: 14 }}>
                                                        {getOptionalStepTitle(index)}
                                                    </p>
                                                ) : null
                                            }
                                        >
                                            {page.step}
                                        </StepLabel>
                                        <StepContent>{content}</StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                {errors.customerID && step === 1 && touched.customerID && (
                                    <FieldError>{errors.customerID}</FieldError>
                                )}
                                {step === pages.length - 1 && (
                                    <>
                                        <Button
                                            onClick={() => {
                                                setInviteUser(false);
                                                submitForm();
                                            }}
                                            fullWidth
                                            style={{ marginBottom: 16 }}
                                            disabled={isSubmitting}
                                            variant="text"
                                        >
                                            {t("addUserForm.button.save")}
                                        </Button>
                                        <InviteButton
                                            isSubmitting={isSubmitting}
                                            onClick={() => {
                                                setInviteUser(true);
                                                submitForm();
                                            }}
                                            values={values}
                                        >
                                            {t("addUserForm.button.saveAndInvite")}
                                        </InviteButton>
                                    </>
                                )}
                                {step < pages.length - 1 && (
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        disabled={isSubmitting || (selectedCustomer && !selectedCustomer.optIn)}
                                    >
                                        {t("button.next")}
                                    </Button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
                {inviteDialog.component}
            </>
        );
    },
);
