import * as React from "react";
import { RegionSelection } from "../components/forms/shared/RegionSelection";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { Region } from "../network/APITypes";
import { useFormikContext } from "formik";

export const useRegionSelection = ({
    name,
    hideBackdrop,
    regions,
    isUserEdit,
}: {
    name: string;
    hideBackdrop?: boolean;
    regions: Region[];
    isUserEdit?: boolean;
}) => {
    const [open, setOpen] = React.useState(false);
    const { setFieldTouched } = useFormikContext<any>();

    const handleClose = () => {
        setFieldTouched(name, true);
        setOpen(false);
    };

    const handleSubmit = () => {
        setOpen(false);
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose} hideBackdrop={hideBackdrop}>
            <RegionSelection
                name={name}
                onClose={handleClose}
                regions={regions}
                isUserEdit={isUserEdit}
                onSubmit={handleSubmit}
            />
        </CustomDrawer>
    ) : null;

    return {
        open() {
            setOpen(true);
        },
        component,
        options: regions.map((region) => ({ value: region.id, label: `${region.externalID} - ${region.description}` })),
    };
};
