import { Autocomplete, MenuItem, TextField, TextFieldProps } from "@mui/material";
import { FieldInputProps, FormikState, getIn, useFormikContext } from "formik";
import * as React from "react";
import { Option } from "../../types";
import { FieldError } from "./FieldError";

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    options?: Option[];
    hideOptional?: boolean;
    form: FormikState<any>;
    onChangeTextField?: (value: string) => void;
    onSelectValue?: (value?: string | number) => void;
    maxLength?: number;
    useFreeSoloValue?: boolean;
    "data-id": string;
};

export const CustomAutocomplete = ({
    options,
    style,
    label,
    type,
    required,
    field,
    form,
    disabled,
    onChangeTextField,
    onSelectValue,
    maxLength,
    useFreeSoloValue,
    "aria-label": ariaLabel,
    "data-id": dataId,
}: IProps) => {
    const { setFieldValue, values } = useFormikContext<any>();

    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    const index = options?.findIndex((option) => option.value === field.value) ?? -1;

    // Use ref, so default value doesn't change when field.value changes
    const defaultValue = React.useRef(index > -1 ? options?.[index] : field.value);

    return (
        <div style={style}>
            <Autocomplete
                disablePortal
                options={options ?? []}
                id={dataId}
                defaultValue={defaultValue.current}
                freeSolo
                value={options?.find((option) => option.value === field.value) || field.value}
                onChange={(event: React.ChangeEvent<any>, value: string | Option | null) => {
                    if (typeof value === "object") {
                        setFieldValue(field.name, value?.value);
                        onSelectValue?.(value?.value);
                    }
                    onChangeTextField?.("");
                }}
                onBlur={field.onBlur}
                fullWidth
                aria-label={ariaLabel}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, maxLength }}
                        name={field.name}
                        type={type}
                        error={showError}
                        label={required ? `${label} *` : label}
                        variant="outlined"
                        disabled={disabled}
                        onChange={(e) => {
                            // Need this, since new RoleGroup is created using the current search term
                            onChangeTextField?.(e.target.value);
                            onSelectValue?.();

                            // Need this for OCI overrides, where the free solo value is used as form value
                            if (useFreeSoloValue) {
                                setFieldValue(field.name, e.target.value);
                            }
                        }}
                    />
                )}
                disabled={disabled}
            />
            <FieldError>{showError && fieldError}</FieldError>
        </div>
    );
};
