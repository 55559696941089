import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { compact } from "lodash";
import { observer } from "mobx-react";
import { useRef, useEffect, useState } from "react";
import { t } from "../../../i18n/util";
import { FieldDefinition, Option } from "../../../types";
import { CustomInputField } from "../../ui/CustomInputField";
import { CustomSelect } from "../../ui/CustomSelect";
import { SiteContainer } from "../../ui/Primitives";
import { Colors } from "../../util/Colors";
import * as Yup from "yup";
import { useOCIResponseMessageListener } from "../../../hooks/useOCIResponseMessageListener";
import { OciTestingTemplateSelection } from "./OciTestingTemplateSelection";

const getOciVersionOptions = (): Option[] => [
    { value: "1.0", label: "1.0" },
    { value: "2.0", label: "2.0" },
    { value: "3.0", label: "3.0" },
    { value: "4.0", label: "4.0" },
    { value: "5.0", label: "5.0" },
];

const getFunctionOptions = (): Option[] => [
    { value: "detail", label: t("screen.interfaces.oci_test.form.function.option.detail.label") },
    { value: "sourcing", label: t("screen.interfaces.oci_test.form.function.option.sourcing.label") },
    { value: "BackgroundSearch", label: t("screen.interfaces.oci_test.form.function.option.Background_search.label") },
    { value: "validate", label: t("screen.interfaces.oci_test.form.function.option.validate.label") },
];

export type OciTestFormikValues = {
    oci_url: string;
    hook_url: string;
    user: string;
    password: string;
    oci_version: string;
    function: string;
    return_target: string;
    matchcode: string;
    amount: string;
    query_string: string;
    vendor: string;
};

type OciTestingFieldDefinition = FieldDefinition & { fullWidth?: boolean; hidden?: boolean };

const getInitialValues = (): OciTestFormikValues => {
    return {
        oci_url: "",
        hook_url: "",
        user: "",
        password: "",
        oci_version: "5.0",
        function: "",
        return_target: "",
        matchcode: "",
        amount: "",
        query_string: "",
        vendor: "",
    };
};

const validationSchema = Yup.object().shape({
    oci_url: Yup.string().required(
        t("validationError.required.custom", { fieldName: t("screen.interfaces.oci_test.form.oci_url.label") }),
    ),
    hook_url: Yup.string().required(
        t("validationError.required.custom", { fieldName: t("screen.interfaces.oci_test.form.hook_url.label") }),
    ),
    user: Yup.string().required(
        t("validationError.required.custom", { fieldName: t("screen.interfaces.oci_test.form.user.label") }),
    ),
    password: Yup.string().required(
        t("validationError.required.custom", { fieldName: t("screen.interfaces.oci_test.form.password.label") }),
    ),
    oci_version: Yup.string().required(
        t("validationError.required.custom", { fieldName: t("screen.interfaces.oci_test.form.oci_version.label") }),
    ),
});

export const OciTestingSite = observer(() => {
    const formikRef = useRef<FormikProps<OciTestFormikValues>>(null);
    const [localHookResponse, setLocalHookResponse] = useState<{
        timestamp: string;
        parameters: Record<string, string>;
    } | null>(null);
    const externalHookResponse = useOCIResponseMessageListener();

    useEffect(() => {
        const storedData = sessionStorage.getItem("ociCallbackData");
        if (storedData) {
            try {
                const parameters = JSON.parse(storedData);
                setLocalHookResponse({
                    timestamp: new Date().toISOString(),
                    parameters,
                });
                sessionStorage.removeItem("ociCallbackData");
            } catch (e) {
                console.error("Failed to parse OCI callback data", e);
            }
        }
    }, []);

    const hookResponse = localHookResponse || externalHookResponse;

    const applyFormikValues = (values: Partial<OciTestFormikValues>): boolean => {
        if (formikRef.current) {
            formikRef.current.setValues((oldValues) => ({
                ...oldValues,
                ...values,
            }));
            return true;
        }
        return false;
    };

    const fields: OciTestingFieldDefinition[] = compact([
        {
            name: "oci_url",
            label: "screen.interfaces.oci_test.form.oci_url.label",
            fullWidth: true,
        },
        {
            name: "hook_url",
            label: "screen.interfaces.oci_test.form.hook_url.label",
            fullWidth: true,
            hidden: true,
        },
        {
            name: "user",
            label: "screen.interfaces.oci_test.form.user.label",
        },
        {
            name: "password",
            label: "screen.interfaces.oci_test.form.password.label",
            type: "password",
        },
        {
            name: "oci_version",
            label: "screen.interfaces.oci_test.form.oci_version.label",
            component: CustomSelect,
            options: getOciVersionOptions(),
        },
    ]);

    const optionalFields: OciTestingFieldDefinition[] = compact([
        {
            name: "function",
            label: "screen.interfaces.oci_test.form.function.label",
            component: CustomSelect,
            options: getFunctionOptions(),
        },
        {
            name: "return_target",
            label: "screen.interfaces.oci_test.form.return_target.label",
            fullWidth: true,
        },
        {
            name: "matchcode",
            label: "screen.interfaces.oci_test.form.matchcode.label",
        },
        {
            name: "amount",
            label: "screen.interfaces.oci_test.form.amount.label",
        },
        {
            name: "query_string",
            label: "screen.interfaces.oci_test.form.query_string.label",
        },
        {
            name: "vendor",
            label: "screen.interfaces.oci_test.form.vendor.label",
        },
    ]);

    const handleSubmit = (values: OciTestFormikValues, formikHelpers: FormikHelpers<OciTestFormikValues>) => {
        const form = document.createElement("form");
        form.method = "post";
        form.action = values.oci_url;

        const params = {
            http_content_charset: "iso-8859-1",
            username: values.user,
            password: values.password,
            hook_url: `${window.location.origin}/oci-callback.html`,
            oci_version: values.oci_version,
            function: values.function ?? "",
            returntarget: values.return_target ?? "",
            productid: "",
            quantity: values.amount ?? "",
            searchstring: values.query_string ?? "",
            vendor: values.vendor ?? "",
        };

        for (const [key, value] of Object.entries(params)) {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value.toString();
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    };

    return (
        <SiteContainer>
            <div>
                <h1 style={{ marginBottom: 32 }}>{t("screen.interfaces.oci_test.headline")}</h1>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        "@media (min-width: 960px)": {
                            flexDirection: "row",
                            alignItems: "start",
                            justifyContent: "space-between",
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            "@media (min-width: 960px)": {
                                margin: "0 auto",
                                width: "50%",
                            },
                        }}
                        className="form-container"
                    >
                        <OciTestingTemplateSelection onApply={applyFormikValues} />
                        <Formik<OciTestFormikValues>
                            validationSchema={validationSchema}
                            innerRef={formikRef}
                            initialValues={getInitialValues()}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, isValid }) => (
                                <Form style={{ display: "flex", flexDirection: "row", gap: 0, flexWrap: "wrap" }}>
                                    {fields.map((field) => {
                                        return (
                                            <Field
                                                name={field.name}
                                                key={field.name}
                                                label={t(field.label)}
                                                component={field.component ?? CustomInputField}
                                                type={field.type ?? "input"}
                                                required={field.required}
                                                options={field.options}
                                                style={{
                                                    width: field.fullWidth ? "100%" : "calc(50% - 8px)",
                                                    margin: field.fullWidth ? 0 : 4,
                                                    display: field.hidden ? "none" : "block",
                                                }}
                                            />
                                        );
                                    })}
                                    <Accordion
                                        style={{ width: "100%" }}
                                        sx={{ boxShadow: "none", "&::before": { display: "none" } }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            sx={{
                                                "&.Mui-expanded": {
                                                    backgroundColor: Colors.GREY_50_BACKGROUND,
                                                },
                                            }}
                                        >
                                            <h4>{t("screen.interfaces.oci_test.optional_fields.headline")}</h4>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{ display: "flex", flexDirection: "row", gap: 16, flexWrap: "wrap" }}
                                        >
                                            {optionalFields.map((field) => {
                                                return (
                                                    <Field
                                                        name={field.name}
                                                        key={field.name}
                                                        label={t(field.label)}
                                                        component={field.component ?? CustomInputField}
                                                        type={field.type ?? "input"}
                                                        required={field.required}
                                                        options={field.options}
                                                        style={{ width: field.fullWidth ? "100%" : "calc(50% - 8px)" }}
                                                    />
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        fullWidth
                                        style={{ marginTop: 16 }}
                                        disabled={!isValid}
                                    >
                                        {t("screen.interfaces.oci_test.form.submit")}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </div>
        </SiteContainer>
    );
});
