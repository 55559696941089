import { Field, useFormikContext } from "formik";
import { GetOCIOverridesResponse } from "../../../network/APITypes";
import { CustomAutocomplete } from "../../ui/CustomAutocomplete";
import { MessageIDS, t } from "../../../i18n/util";
import { MAX_OCI_CONSTANT_LENGTH } from "../../../config";
import { CustomCheckbox } from "../../ui/CustomCheckbox";

const overrideOptions: { value: string; label: MessageIDS }[] = [
    { value: "BaseArticle.Name", label: "oci.base_article.name" },
    { value: "BaseArticle.Matchcode", label: "oci.base_article.matchcode" },
    { value: "Quantity", label: "oci.quantity" },
    { value: "BaseArticle.UnitOfSale", label: "oci.base_article.unit_of_sale" },
    { value: "BaseArticle.BasePrice.Amount", label: "oci.base_article.base_price.amount" },
    { value: "BaseArticle.BasePrice.Unit", label: "oci.base_article.base_price.unit" },
    { value: "BaseArticle.Manufacturer.ShortName", label: "oci.base_article.manufacturer.short_name" },
    { value: "BaseArticle.Manufacturer.Name", label: "oci.base_article.manufacturer.name" },
    { value: "BaseArticle.ManufacturerArticleNumber", label: "oci.base_article.manufacturer_article_number" },
    { value: "BaseArticle.ImageURL", label: "oci.base_article.image_url" },
    { value: "BaseArticle.EClassNumber", label: "oci.base_article.eclass_number" },
    { value: "OrderPosition.DeliveryTimeDays", label: "oci.order_position.delivery_time_days" },
    { value: "Prices.NetPriceWithSurcharges", label: "oci.prices.net_price_with_surcharges" },
    { value: "Prices.NetPrice", label: "oci.prices.net_price" },
    { value: "Prices.NetPriceWithSurchargesPerPiece", label: "oci.prices.net_price_with_surcharges_per_piece" },
    { value: "Prices.GrossPricePerPiece", label: "oci.prices.gross_price_per_piece" },
    { value: "Prices.NetPricePerPiece", label: "oci.prices.net_price_per_piece" },
    { value: "BaseArticle.UnitOfSaleSAP", label: "oci.base_article.unit_of_sale_sap" },
];
// hardcoded default fields until a better solution was found
const defaultFieldNames = [
    "NEW_ITEM-DESCRIPTION[n]",
    "NEW_ITEM-MATNR[n]",
    "NEW_ITEM-QUANTITY[n]",
    "NEW_ITEM-UNIT[n]",
    "NEW_ITEM-PRICE[n]",
    "NEW_ITEM-CURRENCY[n]",
];

export const isOciOverrideValue = (value?: string) => {
    return value ? overrideOptions.map((option) => option.value).includes(value.trim()) : false;
};

// Use this to convert OCI key to Formik compatible names. Formik does not like [, ], (, )
export const ociToFormikName = (ociKey: string) => {
    return ociKey.replace("[", "+").replace("]", "*");
};

export const CustomerDetailsOCIFields = ({ overrides }: { overrides: GetOCIOverridesResponse }) => {
    const { values, setFieldValue } = useFormikContext<any>();
    return (
        <>
            {overrides.ociOverrides
                .sort((a, b) => {
                    if (a.id && !b.id) return -1;

                    if (!a.id && b.id) return 1;

                    const aIsDefault = defaultFieldNames.includes(a.field);
                    const bIsDefault = defaultFieldNames.includes(b.field);

                    if (aIsDefault && bIsDefault) {
                        return a.field.localeCompare(b.field);
                    }

                    if (aIsDefault) return -1;

                    if (bIsDefault) return 1;

                    return a.field.localeCompare(b.field);
                })
                .map((field) => {
                    const overrideFieldName = `${ociToFormikName(field.field)}.value`;
                    const checkmarkFieldName = `${ociToFormikName(field.field)}.useDefault`;
                    const useDefault = values[ociToFormikName(field.field)].useDefault;

                    return (
                        <div style={{ display: "flex", alignItems: "start", gap: 8 }}>
                            <Field
                                key={field.field}
                                component={CustomAutocomplete}
                                label={field.field}
                                name={overrideFieldName}
                                type="input"
                                maxLength={MAX_OCI_CONSTANT_LENGTH}
                                options={overrideOptions.map((option) => ({
                                    value: option.value,
                                    label: t(option.label),
                                }))}
                                useFreeSoloValue
                                style={{ width: "100%" }}
                                disabled={useDefault}
                            />
                            <Field
                                name={checkmarkFieldName}
                                checked={useDefault}
                                component={CustomCheckbox}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.target.checked) {
                                        setFieldValue(
                                            overrideFieldName,
                                            defaultFieldNames.includes(field.field) // hardcoded default fields object used to determine if a field has a default value at this point
                                                ? field.constantValue ?? field.valueKey ?? ""
                                                : "",
                                        );
                                    }
                                }}
                                style={{ marginRight: 4 }}
                            ></Field>
                        </div>
                    );
                })}
        </>
    );
};
