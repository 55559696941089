import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import * as React from "react";
import { createUser } from "../components/forms/addUser/AddUserForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { DialogHeader } from "../components/ui/DialogHeader";
import { t } from "../i18n/util";
import { PostCreateUserInvitePayloadInvitationTypeEnum, PostUserRequestsPayloadV2 } from "../network/APITypes";
import { LoadingButton } from "@mui/lab";

export const useInvitationTypeSelection = ({
    hideBackdrop,
    userPayload,
    onClose,
    onUserCreated,
}: {
    hideBackdrop?: boolean;
    userPayload?: PostUserRequestsPayloadV2;
    onClose?: () => void;
    onUserCreated?: () => void;
}) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        onClose?.();
    };

    const [invitationType, setInvitationType] = React.useState<PostCreateUserInvitePayloadInvitationTypeEnum>("email");

    const handleInvite = async () => {
        if (!userPayload) {
            return;
        }

        setIsLoading(true);

        // Now create user and invite
        await createUser(userPayload, true, invitationType, onUserCreated);

        setIsLoading(false);

        setOpen(false);
        onClose?.();
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose} hideBackdrop={hideBackdrop}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <div>
                    <DialogHeader title={t("user.action.inviteDialog.title")} onClose={handleClose} />
                    <div style={{ marginTop: 40 }}>
                        <RadioGroup
                            value={invitationType}
                            onChange={(event) => {
                                setInvitationType(event.target.value as PostCreateUserInvitePayloadInvitationTypeEnum);
                            }}
                        >
                            <FormControlLabel value="email" control={<Radio />} label={t("invite.email")} />
                            <FormControlLabel
                                value="sms"
                                control={<Radio />}
                                label={t("invite.phone")}
                                style={{ marginTop: 12 }}
                            />
                        </RadioGroup>
                    </div>
                </div>
                <LoadingButton variant="contained" style={{ marginTop: 32 }} onClick={handleInvite} loading={isLoading}>
                    {t("addUserForm.button.saveAndInvite")}
                </LoadingButton>
            </div>
        </CustomDrawer>
    ) : null;

    return {
        open() {
            setOpen(true);
        },
        component,
    };
};
