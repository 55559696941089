/* eslint-disable no-throw-literal */

import { GetAuthenticationTargetResponse } from "../hooks/useAuthTargets";
import {
    CustomerPaginationOptions,
    DomainPaginationOptions,
    GetCustomersOptions,
    GetCustomerStatusOptions,
    RoleDetailsOptions,
    RoleGroupsOptions,
    RolesOptions,
    UserPaginationOptions,
    UserPreferencesPaginationOptions,
} from "../types";
import { apiClient } from "./APIClient";
import {
    AccountTypeDomain,
    AccountTypeDomainPayload,
    BaseRoleGroup,
    CMSCustomer,
    DashboardInfo,
    DeleteUserRequestsPayload,
    DeleteUserRequestsResponse,
    GetAccountTypeDomainsResponse,
    GetCMSCustomersResponse,
    GetCorporatePositionsResponse,
    GetCustomerLockedStatusResponse,
    GetCustomersResponse,
    GetDistributionChannelsResponse,
    GetDistributorsResponse,
    GetOCIOverridesResponse,
    GetPermissionServiceGroupsRespones,
    GetRegionsResponse,
    GetRoleDetailsResponse,
    GetRoleGroupsResponse,
    GetRolesResponse,
    GetUserInfoResponse,
    GetUserPreferencesListResponse,
    GetUserRequestLinkedUserRequestsResponse,
    GetUserRequestPermissionsResponse,
    GetUserRequestsResponse,
    LegalDocumentConsentsResponse,
    OCIOverride,
    OCIOverridePayload,
    PostApproveUserRequestResponse,
    PostCreateUserInvitePayloadInvitationTypeEnum,
    PostCustomerOptOutResponse,
    PostCustomerRequestOptInResponse,
    PostDenyUserRequestResponse,
    PostInviteUserRequestsPayload,
    PostInviteUserRequestsResponse,
    PostLoginResponse,
    PostUserRequestExistPayload,
    PostUserRequestExistsResponse,
    PostUserRequestsCSVPayload,
    PostUserRequestsPayloadV2,
    PutActivateUserRequestsPayload,
    PutActivateUserRequestsResponse,
    PutDeactivateUserRequestsPayload,
    PutDeactivateUserRequestsResponse,
    PutDistributorPayload,
    PutUserRequestPayloadV2,
    PutUserRequestPermissionsPayloadV2,
    PutUserRequestPermissionsResponse,
    PutUserRequestRolesPayload,
    Role,
    RoleGroupPayload,
    RoleGroupRoles,
    RolePayload,
    RoleServicesPayload,
    UserPreferences,
    UserRequest,
} from "./APITypes";

export const API = {
    loginWithPassword(options: { username: string; password: string }): Promise<PostLoginResponse> {
        return apiClient.requestType({
            url: "/api/v1/cms/auth/login",
            method: "POST",
            body: {
                username: options.username,
                password: options.password,
            },
        });
    },

    logout(refreshToken: string) {
        return apiClient.request({
            url: "/api/v1/cms/auth/logout",
            method: "POST",
            body: {
                refresh_token: refreshToken,
            },
        });
    },

    tokenRefresh(refreshToken: string): Promise<PostLoginResponse> {
        const body = { refresh_token: refreshToken };

        return apiClient.requestType(
            {
                url: "/api/v1/cms/auth/refresh",
                method: "POST",
                body,
            },
            true,
        );
    },

    postForgotPassword(email: string) {
        return apiClient.request({
            url: "/api/v1/cms/auth/forgot-password",
            method: "POST",
            body: {
                username: email,
            },
        });
    },

    postChangePassword(password: string, token: string) {
        return apiClient.request({
            url: "/api/v1/cms/auth/forgot-password/complete",
            method: "POST",
            body: {
                password,
                token,
            },
        });
    },

    getUserInfo(): Promise<GetUserInfoResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/auth/userinfo" });
    },

    getDashboardInfo(): Promise<DashboardInfo> {
        return apiClient.requestType({ url: "/api/v1/cms/dashboard-info" });
    },

    getUsers(options?: UserPaginationOptions): Promise<GetUserRequestsResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/user-requests", queryParameters: options });
    },

    getUserDetails(userId: string): Promise<UserRequest> {
        return apiClient.requestType({ url: `/api/v1/cms/user-requests/${userId}` });
    },

    getLinkedUserRequests(userId: string): Promise<GetUserRequestLinkedUserRequestsResponse> {
        return apiClient.requestType({ url: `/api/v1/cms/user-requests/${userId}/linked-user-requests` });
    },

    postInviteUsers(
        body: PostInviteUserRequestsPayload,
        options?: UserPaginationOptions,
        invitationType?: PostCreateUserInvitePayloadInvitationTypeEnum,
    ): Promise<PostInviteUserRequestsResponse> {
        return apiClient.requestType({
            method: "POST",
            url: "/api/v1/cms/user-requests-invite",
            body,
            queryParameters: {
                ...options,
                invitationType,
            },
        });
    },

    putActivateUsers(
        body: PutActivateUserRequestsPayload,
        options?: UserPaginationOptions,
    ): Promise<PutActivateUserRequestsResponse> {
        return apiClient.requestType({
            method: "PUT",
            url: "/api/v1/cms/user-requests-activate",
            body,
            queryParameters: options,
        });
    },

    putDeactivateUsers(
        body: PutDeactivateUserRequestsPayload,
        options?: UserPaginationOptions,
    ): Promise<PutDeactivateUserRequestsResponse> {
        return apiClient.requestType({
            method: "PUT",
            url: "/api/v1/cms/user-requests-deactivate",
            body,
            queryParameters: options,
        });
    },

    deleteUsers(body: DeleteUserRequestsPayload, options?: UserPaginationOptions): Promise<DeleteUserRequestsResponse> {
        return apiClient.requestType({
            method: "DELETE",
            url: "/api/v1/cms/user-requests",
            body,
            queryParameters: options,
        });
    },

    downloadUsersCSV(body: PostUserRequestsCSVPayload, options?: UserPaginationOptions): Promise<any> {
        return apiClient.downloadFile({
            method: "POST",
            url: "/api/v1/cms/user-requests/csv",
            body,
            queryParameters: options,
            fileName: `users-${Date.now()}.csv`,
        });
    },

    getUserPermissions(userId: string, options?: RoleDetailsOptions): Promise<GetUserRequestPermissionsResponse> {
        return apiClient.requestType({
            url: `/api/v1/cms/user-requests/${userId}/permissions`,
            queryParameters: options,
        });
    },

    putUserPermissions(userId: string, body: PutUserRequestPermissionsPayloadV2) {
        return apiClient.request({
            method: "PUT",
            url: `/api/v2/cms/user-requests/${userId}/permissions`,
            body,
        });
    },

    putUserRoles(userId: string, body: PutUserRequestRolesPayload) {
        return apiClient.request({
            method: "PUT",
            url: `/api/v1/cms/user-requests/${userId}/roles`,
            body,
        });
    },

    // VKO filter values
    getDistributors(): Promise<GetDistributorsResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/distributors" });
    },

    putDistributor(distributorId: string, body: PutDistributorPayload): Promise<PutUserRequestPermissionsResponse> {
        return apiClient.requestType({
            method: "PUT",
            url: `/api/v1/cms/distributors/${distributorId}`,
            body,
        });
    },

    // VT-Weg filter values
    getDistributionChannels(): Promise<GetDistributionChannelsResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/distribution-channels" });
    },

    getRegions(): Promise<GetRegionsResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/regions" });
    },

    postApproveUser(userId: string): Promise<PostApproveUserRequestResponse> {
        return apiClient.requestType({
            method: "POST",
            url: `/api/v1/cms/user-requests/${userId}/approve`,
        });
    },

    postDenyUser(userId: string): Promise<PostDenyUserRequestResponse> {
        return apiClient.requestType({
            method: "POST",
            url: `/api/v1/cms/user-requests/${userId}/deny`,
        });
    },

    postCustomerRequestOptIn(customerId: string): Promise<PostCustomerRequestOptInResponse> {
        return apiClient.requestType({
            method: "POST",
            url: `/api/v1/cms/customers/${customerId}/request-opt-in`,
        });
    },

    postCustomerOptOut(customerId: string): Promise<PostCustomerOptOutResponse> {
        return apiClient.requestType({
            method: "POST",
            url: `/api/v1/cms/customers/${customerId}/opt-out`,
        });
    },

    postCustomerForceOptIn(customerId: string): Promise<PostCustomerOptOutResponse> {
        return apiClient.requestType({
            method: "POST",
            url: `/api/v1/cms/customers/${customerId}/force-opt-in`,
        });
    },

    getCoreDataCorporatePositions(target: string): Promise<GetCorporatePositionsResponse> {
        return apiClient.requestType({ url: `/api/v1/${target}/core-data/corporate-positions` });
    },

    getDistributorCustomers(distributorId: string, options?: GetCustomersOptions): Promise<GetCustomersResponse> {
        return apiClient.requestType({
            url: `/api/v1/cms/distributors/${distributorId}/customers`,
            queryParameters: options,
        });
    },

    getCustomerStatus(options: GetCustomerStatusOptions): Promise<GetCustomerLockedStatusResponse> {
        return apiClient.requestType({
            url: `/api/v1/cms/distributors/${options.distributorId}/customers/${options.customerId}/regions/${options.regionId}/distributionChannels/${options.distributionChannelId}/locked-status`,
        });
    },

    getLegalDocumentConsents(userId: string): Promise<LegalDocumentConsentsResponse> {
        return apiClient.requestType({
            url: `/api/v1/cms/user-requests/${userId}/legal-document-consents`,
        });
    },

    postUserRequest(body: PostUserRequestsPayloadV2): Promise<UserRequest> {
        return apiClient.requestType({
            method: "POST",
            url: "/api/v2/cms/user-requests",
            body,
        });
    },

    postUserRequestExists(body: PostUserRequestExistPayload): Promise<PostUserRequestExistsResponse> {
        return apiClient.requestType({
            method: "POST",
            url: "/api/v2/cms/user-requests/exists",
            body,
        });
    },

    putUserRequest(userId: string, body: PutUserRequestPayloadV2): Promise<UserRequest> {
        return apiClient.requestType({
            method: "PUT",
            url: `/api/v2/cms/user-requests/${userId}`,
            body,
        });
    },

    postExpoTickets(file: File) {
        const data = new FormData();
        data.append("file", file);

        return apiClient.uploadFormData({
            method: "POST",
            url: "/api/v1/cms/expo/tickets",
            body: data,
        });
    },

    getDomains(options?: DomainPaginationOptions): Promise<GetAccountTypeDomainsResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/domains", queryParameters: options });
    },

    postDomain(body: AccountTypeDomainPayload): Promise<AccountTypeDomain> {
        return apiClient.requestType({
            method: "POST",
            url: "/api/v1/cms/domains",
            body,
        });
    },

    putDomain(domainId: string, body: AccountTypeDomainPayload): Promise<AccountTypeDomain> {
        return apiClient.requestType({
            method: "PUT",
            url: `/api/v1/cms/domains/${domainId}`,
            body,
        });
    },

    deleteDomain(domainId: string) {
        return apiClient.request({
            method: "DELETE",
            url: `/api/v1/cms/domains/${domainId}`,
        });
    },

    getRoles(options?: RolesOptions): Promise<GetRolesResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/roles", queryParameters: options });
    },

    getRoleGroups(options?: RoleGroupsOptions): Promise<GetRoleGroupsResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/role-groups", queryParameters: options });
    },

    getRoleGroup(roleGroupId: string): Promise<RoleGroupRoles> {
        return apiClient.requestType({ url: `/api/v1/cms/role-groups/${roleGroupId}` });
    },

    postRole(body: RolePayload): Promise<Role> {
        return apiClient.requestType({
            method: "POST",
            url: "/api/v1/cms/roles",
            body,
        });
    },

    postRoleGroup(body: RoleGroupPayload): Promise<BaseRoleGroup> {
        return apiClient.requestType({
            method: "POST",
            url: "/api/v1/cms/role-groups",
            body,
        });
    },

    getRole(roleId: string, options?: RoleDetailsOptions): Promise<GetRoleDetailsResponse> {
        return apiClient.requestType({ url: `/api/v1/cms/roles/${roleId}`, queryParameters: options });
    },

    putRole(roleId: string, body: RolePayload): Promise<Role> {
        return apiClient.requestType({
            method: "PUT",
            url: `/api/v1/cms/roles/${roleId}`,
            body,
        });
    },

    putRoleGroup(roleGroupId: string, body: RoleGroupPayload): Promise<Role> {
        return apiClient.requestType({
            method: "PUT",
            url: `/api/v1/cms/role-groups/${roleGroupId}`,
            body,
        });
    },

    putRoleGroupRoles(roleGroupId: string, roleIds: string[]): Promise<RoleGroupRoles> {
        return apiClient.requestType({
            method: "PUT",
            url: `/api/v1/cms/role-groups/${roleGroupId}/roles`,
            body: {
                roleIdentifiers: roleIds,
            },
        });
    },

    deleteRole(roleId: string) {
        return apiClient.request({
            method: "DELETE",
            url: `/api/v1/cms/roles/${roleId}`,
        });
    },

    deleteRoleGroup(roleGroupId: string) {
        return apiClient.request({
            method: "DELETE",
            url: `/api/v1/cms/role-groups/${roleGroupId}`,
        });
    },

    activateRole(roleId: string) {
        return apiClient.request({
            method: "PUT",
            url: `/api/v1/cms/roles/${roleId}/activate`,
        });
    },

    deactivateRole(roleId: string) {
        return apiClient.request({
            method: "PUT",
            url: `/api/v1/cms/roles/${roleId}/deactivate`,
        });
    },

    putRoleServices(roleId: string, body: RoleServicesPayload) {
        return apiClient.request({
            method: "PUT",
            url: `/api/v1/cms/roles/${roleId}/services`,
            body,
        });
    },

    getServiceGroups(): Promise<GetPermissionServiceGroupsRespones> {
        return apiClient.requestType({ url: "/api/v1/cms/permission-service-groups" });
    },

    postResetUserPassword(userId: string, options?: { notificationType?: string }) {
        return apiClient.request({
            method: "POST",
            url: `/api/v1/cms/user-requests/${userId}/reset-password`,
            queryParameters: options,
        });
    },

    getCustomers(options: CustomerPaginationOptions): Promise<GetCMSCustomersResponse> {
        return apiClient.requestType({
            url: `/api/v1/cms/customers`,
            queryParameters: options,
        });
    },

    getCustomer(customerId: string): Promise<CMSCustomer> {
        return apiClient.requestType({
            url: `/api/v1/cms/customers/${customerId}`,
        });
    },

    getCustomerOciOverrides(customerId: string): Promise<GetOCIOverridesResponse> {
        return apiClient.requestType({
            url: `/api/v1/cms/customers/${customerId}/oci-overrides`,
        });
    },

    updateOverride(customerId: string, override: OCIOverride) {
        if (override.id) {
            return this.putCustomerOciOverride(customerId, override.id, override);
        } else {
            return this.postCustomerOciOverride(customerId, override);
        }
    },

    postCustomerOciOverride(customerId: string, body: OCIOverridePayload) {
        return apiClient.request({
            method: "POST",
            url: `/api/v1/cms/customers/${customerId}/oci-overrides`,
            body,
        });
    },

    putCustomerOciOverride(customerId: string, overrideId: string, body: OCIOverridePayload) {
        return apiClient.request({
            method: "PUT",
            url: `/api/v1/cms/customers/${customerId}/oci-overrides/${overrideId}`,
            body,
        });
    },

    deleteCustomerOciOverride(customerId: string, overrideId: string) {
        return apiClient.request({
            method: "DELETE",
            url: `/api/v1/cms/customers/${customerId}/oci-overrides/${overrideId}`,
        });
    },

    getUserPreference(userPreferencesId: string): Promise<UserPreferences> {
        return apiClient.requestType({ url: `/api/v1/cms/user-preferences/${userPreferencesId}` });
    },

    getUserPreferences(options?: UserPreferencesPaginationOptions): Promise<GetUserPreferencesListResponse> {
        return apiClient.requestType({ url: "/api/v1/cms/user-preferences", queryParameters: options });
    },

    putIdsAlternativeMapping(customerId: string, alternativeIDSMapping: boolean) {
        return apiClient.request({
            method: "PUT",
            url: `/api/v1/cms/customers/${customerId}/ids-alternative-mapping`,
            body: {
                alternativeIDSMapping: alternativeIDSMapping,
            },
        });
    },
    getAuthTargets(): Promise<GetAuthenticationTargetResponse> {
        return apiClient.requestType({
            url: `/api/v1/cms/authentication-targets`,
        });
    },
};
