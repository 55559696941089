import * as React from "react";
import { CorporatePositionSelection } from "../components/forms/shared/CorporatePositionSelection";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { useCoreDataCorporatePositions } from "./useCoreDataCorporatePositions";
import { useFormikContext } from "formik";

export const useCorporatePositionSelection = ({
    target,
    name,
    hideBackdrop,
}: {
    target: string;
    name: string;
    hideBackdrop?: boolean;
}) => {
    const [open, setOpen] = React.useState(false);
    const corporatePositions = useCoreDataCorporatePositions(target);
    const { setFieldTouched } = useFormikContext<any>();

    const handleClose = () => {
        setFieldTouched(name, true);
        setOpen(false);
    };

    const handleSubmit = () => {
        setOpen(false);
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose} hideBackdrop={hideBackdrop}>
            <CorporatePositionSelection
                name={name}
                positions={corporatePositions ?? []}
                onClose={handleClose}
                onSubmit={handleSubmit}
            />
        </CustomDrawer>
    ) : null;

    return {
        open() {
            setOpen(true);
        },
        component,
        options:
            corporatePositions
                ?.flatMap((category) => category.roles)
                .map((role) => ({ value: role.id, label: role.name })) ?? [],
    };
};
