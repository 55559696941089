import { useState } from "react";
import { OciTestFormikValues } from "./OciTestingSite";
import { Colors } from "../../util/Colors";
import { Button, MenuItem, TextField } from "@mui/material";
import { t } from "../../../i18n/util";

const getOciHookUrl = (): string => `${window.location.origin}/oci-callback.html`;

const getOciUrl = (vko: VKOShortname, env: OciTestEnvironment): string =>
    `https://${env === "prod" ? "fbpprod" : "fbptest"}.fts.at/api/v1/${vko.toLowerCase()}/interfaces/oci`;

type VKOShortname = "SHT" | "OAG" | "KON" | "ELM";

const vkoShortnames: VKOShortname[] = ["SHT", "OAG", "KON", "ELM"];
type OciTestEnvironment = "test" | "prod";

export const OciTestingTemplateSelection = (props: { onApply: (values: Partial<OciTestFormikValues>) => boolean }) => {
    const [vko, setVko] = useState<VKOShortname | "">("");
    const [env, setEnv] = useState<OciTestEnvironment | "">("");
    return (
        <div style={{ border: `1px solid ${Colors.GREY_200}`, borderRadius: 8, padding: 16, marginBottom: 16 }}>
            <h4 style={{ marginBottom: 16 }}> {t("screen.interfaces.oci_test.template.form.headline")}</h4>
            <div style={{ display: "flex", flexDirection: "column", gap: 16, alignItems: "start" }}>
                <TextField
                    select
                    value={vko}
                    label={t("screen.interfaces.oci_test.template.form.vko-selection.label")}
                    onChange={(e) => setVko(e.target.value as VKOShortname)}
                    fullWidth
                >
                    {vkoShortnames.map((vko) => (
                        <MenuItem key={vko} value={vko}>
                            {vko}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label={t("screen.interfaces.oci_test.template.form.environment.label")}
                    value={env}
                    onChange={(e) => setEnv(e.target.value as OciTestEnvironment)}
                    fullWidth
                >
                    <MenuItem value="test">{t("common.test")}</MenuItem>
                    <MenuItem value="prod">{t("common.production")}</MenuItem>
                </TextField>

                <Button
                    variant="contained"
                    style={{ flexShrink: 0 }}
                    disabled={!vko || !env}
                    onClick={() => {
                        if (vko && env) {
                            props.onApply({ hook_url: getOciHookUrl(), oci_url: getOciUrl(vko, env) });
                        }
                    }}
                >
                    {t("common.apply")}
                </Button>
            </div>
        </div>
    );
};
