import { CmsTableCell, CmsTableContainer, SettingSubSiteContainer } from "../../ui/Primitives";
import { Alert, styled, Table, TableRow } from "@mui/material";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { MessageIDS, t } from "../../../i18n/util";
import { AccountTypes } from "../../util/AccountTypes";
import _ from "lodash";
import { AccountType, CustomerType } from "../../../network/APITypes";
import { getCustomerTypeText } from "../../util/Helpers";

type Platform = "web" | "pp" | "fbp";

const getFBPPlatformLabel = (platform: Platform) => {
    switch (platform) {
        case "pp":
            return t("fbp.corporate_context.partner.platform.label");
        case "web":
            return t("fbp.corporate_context.customer.platform.label");
        default:
            return t("fbp.corporate_context.fbp.platform.label");
    }
};

type TableBodyContent = {
    accountType: AccountType;
    platform: Platform;
    customerType?: CustomerType;
    defaultRole: MessageIDS;
};

const ParagraphHeadline = styled("h4")(({ theme }) => ({
    margin: theme.spacing(2, 0),
}));

const StyledOrderedList = styled("ol")({
    padding: 4,
});

const StyledListItem = styled("li")({
    color: "var(--body1)",
    listStylePosition: "inside",
});

export const SettingsAccountTypesSite = () => {
    const headerFields: TableHeaderConfig[] = [
        { column: "accountType", label: "table.label.accountType" },
        { column: "platform", label: "table.label.platform" },
        { column: "customerType", label: "table.label.fbp_context" },
        { column: "defaultRole", label: "table.label.defaultRole" },
    ];

    const tableBodyContent: TableBodyContent[] = [
        {
            accountType: "employee",
            platform: "web",
            customerType: "customer",
            defaultRole: "role.vko_employee",
        },
        {
            accountType: "employee",
            platform: "pp",
            customerType: "supplier",
            defaultRole: "role.category_manager",
        },
        {
            accountType: "employee",
            platform: "pp",
            customerType: "manufacturer",
            defaultRole: "role.category_manager",
        },
        {
            accountType: "employee",
            platform: "pp",
            customerType: "partner",
            defaultRole: "role.category_manager",
        },
        {
            accountType: "employee",
            platform: "fbp",
            customerType: undefined,
            defaultRole: "screen.settings.accountTypes.roles.automatic",
        },
        {
            accountType: "b2b-customer",
            platform: "web",
            customerType: "customer",
            defaultRole: "role.b2b_employee_non_ordering",
        },
        {
            accountType: "b2b-customer",
            platform: "fbp",
            customerType: "customer",
            defaultRole: "screen.settings.accountTypes.roles.automatic",
        },
        {
            accountType: "supplier",
            platform: "web",
            customerType: "customer",
            defaultRole: "role.supplier_employee_non_ordering",
        },
        {
            accountType: "supplier",
            platform: "pp",
            customerType: "supplier",
            defaultRole: "role.supplier_employee",
        },
        {
            accountType: "supplier",
            platform: "fbp",
            customerType: undefined,
            defaultRole: "screen.settings.accountTypes.roles.automatic",
        },
        {
            accountType: "manufacturer",
            platform: "web",
            customerType: "customer",
            defaultRole: "role.manufacturer_employee_non_ordering",
        },
        {
            accountType: "manufacturer",
            platform: "pp",
            customerType: "manufacturer",
            defaultRole: "role.partner_employee",
        },
        {
            accountType: "manufacturer",
            platform: "fbp",
            customerType: undefined,
            defaultRole: "screen.settings.accountTypes.roles.automatic",
        },
        {
            accountType: "partner",
            platform: "web",
            customerType: "customer",
            defaultRole: "role.manufacturer_employee_non_ordering",
        },
        {
            accountType: "partner",
            platform: "pp",
            customerType: "partner",
            defaultRole: "role.partner_employee",
        },
        {
            accountType: "partner",
            platform: "fbp",
            customerType: undefined,
            defaultRole: "screen.settings.accountTypes.roles.automatic",
        },
    ];

    return (
        <SettingSubSiteContainer>
            <div
                style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", maxWidth: 920, margin: "0 auto" }}
            >
                <Alert severity="info" style={{ marginBottom: 32 }}>
                    {t("screen.settings.accountTypes.alert.text")}
                </Alert>
                <CmsTableContainer>
                    <Table style={{ tableLayout: "fixed" }}>
                        <TableHeader headerFields={headerFields} />
                        {tableBodyContent.map((content, index) => {
                            return (
                                <TableRow key={index}>
                                    <CmsTableCell>{t(AccountTypes[content.accountType])}</CmsTableCell>
                                    <CmsTableCell>{getFBPPlatformLabel(content.platform)}</CmsTableCell>
                                    <CmsTableCell>
                                        {content.customerType
                                            ? getCustomerTypeText(content.customerType)
                                            : t("screen.settings.accountTypes.all_numbers")}
                                    </CmsTableCell>
                                    <CmsTableCell>{t(content.defaultRole)}</CmsTableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                </CmsTableContainer>
                <div style={{ width: "100%", margin: "32px 0" }}>
                    <ParagraphHeadline>
                        {t("screen.settings.accountTypes.paragraphHeadline.accountType")}
                    </ParagraphHeadline>
                    <p>{t("screen.settings.accountTypes.paragraph.accountType")}</p>
                    <StyledOrderedList>
                        <StyledListItem>{t("screen.settings.accountTypes.listItem.manualAssignment")}</StyledListItem>
                        <StyledListItem>
                            {t("screen.settings.accountTypes.listItem.automaticAssignment")}
                        </StyledListItem>
                    </StyledOrderedList>
                    <ParagraphHeadline>
                        {t("screen.settings.accountTypes.paragraphHeadline.companyContext")}
                    </ParagraphHeadline>
                    <p>{t("screen.settings.accountTypes.paragraph.companyContext")}</p>
                    <StyledOrderedList>
                        <StyledListItem>{getCustomerTypeText("customer")}</StyledListItem>
                        <StyledListItem>{getCustomerTypeText("supplier")}</StyledListItem>
                        <StyledListItem>{getCustomerTypeText("manufacturer")}</StyledListItem>
                        <StyledListItem>{getCustomerTypeText("partner")}</StyledListItem>
                    </StyledOrderedList>
                    <ParagraphHeadline>
                        {t("screen.settings.accountTypes.paragraphHeadline.defaultRoles")}
                    </ParagraphHeadline>
                    <p>{t("screen.settings.accountTypes.paragraph.defaultRoles")}</p>
                </div>
            </div>
        </SettingSubSiteContainer>
    );
};
