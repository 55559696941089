import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AccountType } from "../../../network/APITypes";
import { t } from "../../../i18n/util";
import { customerAccountTypes, partnerPortalAccountTypes } from "../../../types";
import { getAccountTypeText } from "../../util/Helpers";

type AccountTypeSelectionProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickNext: () => void;
    value: AccountType;
};
export const AccountTypeSelection = (props: AccountTypeSelectionProps) => {
    return (
        <FormControl
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flex: 1,
            }}
        >
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={(event) => props.onChange(event)}
                value={props.value}
            >
                <div>
                    <div style={{ fontWeight: "bold", marginBottom: 4 }}>
                        {t("fbp.corporate_context.customer.platform.label")}
                    </div>
                    {customerAccountTypes.map((accountType) => (
                        <FormControlLabel
                            control={<Radio />}
                            key={accountType}
                            label={<span style={{ fontSize: "0.8rem" }}>{getAccountTypeText(accountType)}</span>}
                            value={accountType}
                        />
                    ))}
                </div>
                <div style={{ marginTop: 24 }}>
                    <div style={{ fontWeight: "bold", marginBottom: 4 }}>
                        {t("fbp.corporate_context.partner.platform.label")}
                    </div>
                    {partnerPortalAccountTypes.map((accountType) => (
                        <FormControlLabel
                            control={<Radio />}
                            key={accountType}
                            label={<span style={{ fontSize: "0.8rem" }}>{getAccountTypeText(accountType)}</span>}
                            value={accountType}
                        />
                    ))}
                </div>
            </RadioGroup>
            <Button variant="contained" onClick={props.onClickNext}>
                {t("button.next")}
            </Button>
        </FormControl>
    );
};
