import {
    Button,
    ButtonBase,
    ClickAwayListener,
    Fade,
    IconButton,
    InputAdornment,
    Skeleton,
    TextField,
    Tooltip,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import { uniq } from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { CARD_WIDTH } from "../../../config";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useCopyPermissionServicesDialog } from "../../../hooks/useCopyPermissionServicesDialog";
import { useEditUserForm } from "../../../hooks/useEditUserForm";
import { useLegalDocumentConsentsDialog } from "../../../hooks/useLegalDocumentConsentsDialog";
import { useLinkedUserRequests } from "../../../hooks/useLinkedUserRequests";
import { useManagePermissionsDialog } from "../../../hooks/useManagePermissionsDialog";
import { useRoleSelection } from "../../../hooks/useRoleSelection";
import { useUserDetails } from "../../../hooks/useUserDetails";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { PostCreateUserInvitePayloadInvitationTypeEnum } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { CardAction } from "../../ui/CardAction";
import { CardHeader } from "../../ui/CardHeader";
import { DetailsHeader } from "../../ui/DetailsHeader";
import { GrantedPermissionServicesList } from "../../ui/GrantedPermissionServicesList";
import { InfoBlock } from "../../ui/InformationRow";
import { LinkButton } from "../../ui/LinkButton";
import { Card, CardColumn, CardContainer, CardDataContainer } from "../../ui/Primitives";
import { UserStatus } from "../../ui/Status";
import { AccountTypes } from "../../util/AccountTypes";
import { Colors } from "../../util/Colors";
import { getUserDialogConfiguration } from "../../util/Dialogs";
import { getFormattedDate, hasUserManagement } from "../../util/Helpers";
import { Icon } from "../../util/Icon";
import { UserRequestSources, UserRequestSourcesType } from "../../util/UserRequestSources";
import { ContentCopy } from "@mui/icons-material";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import { useDistributors } from "../../../hooks/useDistributors";
import { EmptyState } from "../../ui/EmptyState";
import CloseIcon from "@mui/icons-material/Close";
import { ITab } from "../../../types";
import { useUserPermissions } from "../../../hooks/useUserPermissions";
import { useUserPreference } from "../../../hooks/useUserPreference";

const PASSWORD_RESET_NOTIFICATION_TYPE_SMS = "sms";

// Shows email/phone status
const ConfirmationIcon = ({
    confirmationPending,
    type,
}: {
    confirmationPending?: boolean;
    type: "email" | "phone";
}) => {
    let tooltipText = t(
        confirmationPending ? "screen.userDetails.emailNotConfirmed" : "screen.userDetails.emailConfirmed",
    );

    if (type === "phone") {
        tooltipText = t(
            confirmationPending ? "screen.userDetails.phoneNotConfirmed" : "screen.userDetails.phoneConfirmed",
        );
    }

    return (
        <Tooltip title={tooltipText}>
            <div>
                <Icon
                    name="verified"
                    style={{
                        color: confirmationPending ? Colors.DISABLED : Colors.PRIMARY_500,
                    }}
                />
            </div>
        </Tooltip>
    );
};

export const UserDetailsSite = observer(
    ({
        userId,
        isActive,
        reloadUsers,
        setTab,
        onCloseUserDetails,
        onOpenLinkedUser,
    }: {
        userId: string;
        isActive: boolean;
        reloadUsers: () => Promise<void>;
        setTab: (tab: ITab) => void;
        onCloseUserDetails: (userId: string) => void;
        onOpenLinkedUser: (tabId: string) => void;
    }) => {
        const { user, reloadUser, userIsLoading } = useUserDetails(userId);
        const linkedUserRequests = useLinkedUserRequests(userId);
        const [customerExternalId, setCustomerExternalId] = React.useState("");
        const legalDocumentConsentsDialog = useLegalDocumentConsentsDialog({ userId });
        const [copiedText, copy] = useCopyToClipboard();
        const [copyTooltipOpen, setCopyTooltipOpen] = React.useState(false);
        const { getDistributorShortName } = useDistributors();
        const userPermissions = useUserPermissions({ id: userId, options: { orderDir: "asc" } });
        const reload = async () => {
            await reloadUser();
            await userPermissions.reload();
            reloadUsers();
        };

        const managePermissionsDialog = useManagePermissionsDialog({
            title: t("addPermissionServices.title"),
            user,
            reloadUser,
            onSubmit: reload,
            linkedUsers: linkedUserRequests?.userRequests,
        });

        React.useEffect(() => {
            if (user) {
                setTab({ id: userId, values: [user.externalCustomerID, `${user.lastName} ${user.firstName}`] });
            }

            if (!user && !userIsLoading) {
                setTab({ id: userId, values: [] });
            }
        }, [setTab, user, userId, userIsLoading]);

        React.useEffect(() => {
            if (isActive && user?.customerLockedStatus === "locked") {
                generalStore.setCustomerLocked(true);
            }

            if ((isActive && user?.customerLockedStatus !== "locked") || (!userIsLoading && !user)) {
                generalStore.setCustomerLocked(false);
            }
        }, [user?.customerLockedStatus, isActive, userIsLoading, user]);

        useDeepCompareEffect(() => {
            if (linkedUserRequests?.userRequests && user?.externalCustomerID) {
                setCustomerExternalId(
                    linkedUserRequests.userRequests?.find(
                        (userRequest) => userRequest.externalCustomerID === user?.externalCustomerID,
                    )?.externalCustomerID ?? "",
                );
            }
        }, [linkedUserRequests?.userRequests, user?.externalCustomerID]);

        useDeepCompareEffect(() => {
            if (isActive && user) {
                generalStore.setUser(user);
            }

            if (!userIsLoading && !user) {
                generalStore.setUser(undefined);
            }
        }, [user, isActive, userIsLoading]);

        useDeepCompareEffect(() => {
            if (generalStore.forceReloadUser) {
                reload();
                runInAction(() => {
                    generalStore.forceReloadUser = false;
                });
            }
        }, [generalStore.forceReloadUser]);

        const editRoleDrawer = useRoleSelection({
            user,
            reloadUser: reload,
        });

        const handleClickAddRole = () => {
            if (user) {
                editRoleDrawer.open("addRole");
            }
        };

        const handleClickEditRole = (roleId: string) => {
            if (user) {
                editRoleDrawer.open("editRole", roleId);
            }
        };

        const handleClickDeleteRole = async (roleId: string) => {
            if (user) {
                try {
                    generalStore.setIsLoading(true);

                    await API.putUserRoles(user.id, {
                        roleIdentifiers: user.roles?.filter((role) => role.id !== roleId).map((role) => role.id) ?? [],
                    });

                    await reload();
                    generalStore.setSuccessMessage(t("common.success"));
                } catch (error) {
                    generalStore.setError(t("error.deleteRole"), error);
                } finally {
                    generalStore.setIsLoading(false);
                }
            }
        };

        const handleChangeSelectedCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
            const externalCustomerId = event.target.value;
            const linkedUserId = linkedUserRequests?.userRequests?.find(
                (userRequest) => userRequest.externalCustomerID === externalCustomerId,
            )?.id;
            if (linkedUserId) {
                onOpenLinkedUser(linkedUserId);
            }
        };

        const handleSubmitInviteDialog = (invitationType: PostCreateUserInvitePayloadInvitationTypeEnum) => {
            if (invitationType === "sms") {
                if (!user?.phone || hasUserManagement(user)) {
                    // No phone -> disable phone invitation
                    return;
                }
            } else {
                if (!user?.email) {
                    // No email -> disable email invitation
                    return;
                }
            }

            return async () => {
                try {
                    generalStore.setIsLoading(true);
                    await API.postInviteUsers([userId], undefined, invitationType);
                    await reload();
                    generalStore.setSuccessMessage(t("common.success"));
                } catch (error) {
                    generalStore.setError(t("error.inviteUsers"), error);
                } finally {
                    generalStore.setIsLoading(false);
                }
            };
        };

        const handleSubmitActivateDialog = async () => {
            try {
                generalStore.setIsLoading(true);
                await API.putActivateUsers([userId]);
                await reload();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.activateUsers"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        };

        const handleSubmitDeactivateDialog = async () => {
            try {
                generalStore.setIsLoading(true);
                await API.putDeactivateUsers([userId]);
                await reload();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deactivateUsers"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        };

        const handleSubmitDeleteDialog = async () => {
            try {
                generalStore.setIsLoading(true);
                await API.deleteUsers([userId]);
                reloadUsers();
                onCloseUserDetails(userId);
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deleteUsers"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        };

        const handleSubmitForceUserManagementDialog = async () => {
            if (user?.customer) {
                try {
                    generalStore.setIsLoading(true);
                    await API.postCustomerForceOptIn(user?.customer?.id);
                    await reload();
                    generalStore.setSuccessMessage(t("common.success"));
                } catch (error) {
                    generalStore.setError(t("error.customerOptOut"), error);
                } finally {
                    generalStore.setIsLoading(false);
                }
            }
        };

        const handleResetPassword = async () => {
            try {
                generalStore.setIsLoading(true);
                await API.postResetUserPassword(userId);
                await reload();
                generalStore.setSuccessMessage(t("success.resetPassword"));
            } catch (error) {
                generalStore.setError(t("error.resetPassword"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        };

        const handleResetPasswordBySMS = async () => {
            try {
                generalStore.setIsLoading(true);
                await API.postResetUserPassword(userId, { notificationType: PASSWORD_RESET_NOTIFICATION_TYPE_SMS });
                await reload();
                generalStore.setSuccessMessage(t("success.resetPassword"));
            } catch (error) {
                generalStore.setError(t("error.resetPassword"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        };

        const handleBack = () => {
            onCloseUserDetails(userId);
        };
        const { userPreference } = useUserPreference(user?.userPreferencesId);

        const userDataEditDrawer = useEditUserForm({ user: user, reloadUser: reload, userPreference: userPreference });

        const handleClickEditPersonalData = userDataEditDrawer.open;

        const inviteDialog = useConfirmationDialog({
            ...getUserDialogConfiguration(user?.status === "invited" ? "reinvite" : "invite", { user }),
            onSubmit: handleSubmitInviteDialog("email"),
            onSecondaryAction: handleSubmitInviteDialog("sms"),
            secondaryActionTooltip:
                user?.phone && hasUserManagement(user) ? t("userManagementInviteEmailOnly.tooltip") : undefined,
        });

        const activateDialog = useConfirmationDialog({
            ...getUserDialogConfiguration("activate", { user }),
            onSubmit: handleSubmitActivateDialog,
        });

        const deactivateDialog = useConfirmationDialog({
            ...getUserDialogConfiguration("deactivate", { user }),
            onSubmit: handleSubmitDeactivateDialog,
        });

        const deleteDialog = useConfirmationDialog({
            ...getUserDialogConfiguration("delete", { user }),
            onSubmit: handleSubmitDeleteDialog,
        });

        const forceUserManagementDialog = useConfirmationDialog({
            ...getUserDialogConfiguration("forceUserManagement", { user }),
            onSubmit: handleSubmitForceUserManagementDialog,
        });

        const handleChangeUserPermissions = async (permissionServiceIds: string[]) => {
            if (user) {
                const currentPermissionServiceIds = user.permissionServices
                    ?.filter((permissionService) => permissionService.isGranted)
                    .map((permissionService) => permissionService.identifier);

                try {
                    generalStore.setIsLoading(true);
                    await API.putUserPermissions(userId, {
                        permissionServices: uniq([...(currentPermissionServiceIds ?? []), ...permissionServiceIds]),
                    });
                    await reload();
                    generalStore.setSuccessMessage(t("common.success"));
                } catch (error) {
                    generalStore.setError(t("error.savePermissionServices"), error);
                } finally {
                    generalStore.setIsLoading(false);
                }
            }
        };

        const copyPermissionServicesDialog = useCopyPermissionServicesDialog({
            currentUser: user,
            onSubmit: handleChangeUserPermissions,
        });

        let userPhone = user?.phone;
        let userEmail = user?.email;
        let userName = `${user?.lastName}, ${user?.firstName}`;
        let position = user?.corporatePosition?.role.name;

        const appUserProfile = user?.appUserProfile;
        if (appUserProfile) {
            userPhone = appUserProfile?.phoneNumber;
            userEmail = appUserProfile?.email;
            userName = `${appUserProfile.lastName}, ${appUserProfile.firstName}`;
            position = appUserProfile?.role?.name;
        }

        let resetPasswordDialog;

        if (userEmail && userPhone) {
            resetPasswordDialog = useConfirmationDialog({
                ...getUserDialogConfiguration("resetPasswordByMethod", { user }),
                onSubmit: handleResetPassword,
                onSecondaryAction: handleResetPasswordBySMS,
            });
        } else {
            resetPasswordDialog = useConfirmationDialog({
                ...getUserDialogConfiguration("resetPassword", { user }),
                onSubmit: userPhone ? handleResetPasswordBySMS : handleResetPassword,
            });
        }

        const emailConfirmationPending = appUserProfile?.emailConfirmationPending;
        const phoneConfirmationPending = appUserProfile?.phoneNumberConfirmationPending;

        let inviteTooltip = !user?.canBeInvited
            ? t(
                  user?.status === "active"
                      ? "button.invite.tooltip.alreadyActive"
                      : "button.invite.tooltip.noPermission",
              )
            : "";

        const userManagementNoEmail = hasUserManagement(user) && !user?.email;
        if (userManagementNoEmail) {
            inviteTooltip = t("userManagementInviteEmailOnly.tooltip");
        }

        const handleCopyTooltipClose = () => {
            setCopyTooltipOpen(false);
        };

        const dateFormat = "DD/MM/YYYY";

        if (!user && userIsLoading) {
            return null;
        }

        if (!user) {
            return (
                <div style={{ display: "flex", justifyContent: "center", marginTop: 32 }}>
                    <Fade in>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <EmptyState
                                iconName="search"
                                title={t("screen.userDetails.emptyScreen.title")}
                                description={t("screen.userDetails.emptyScreen.description")}
                            />
                            <div style={{ display: "flex", justifyContent: "center", marginTop: 36, gap: 20 }}>
                                <Button type="button" variant="contained" onClick={() => reloadUser()}>
                                    {t("screen.userDetails.emptyScreen.reload.button.text")}
                                </Button>
                                <ButtonBase onClick={() => onCloseUserDetails(userId)}>
                                    <CloseIcon fontSize="small" />
                                    <span
                                        style={{
                                            color: Colors.PRIMARY_700,
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("screen.userDetails.emptyScreen.close.text")}
                                    </span>
                                </ButtonBase>
                            </div>
                        </div>
                    </Fade>
                </div>
            );
        } else {
            return (
                <Fade in={true}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <DetailsHeader title={userName} onBack={handleBack} />
                                {linkedUserRequests?.userRequests ? (
                                    <Fade in>
                                        <TextField
                                            variant="outlined"
                                            label={t("screen.userDetails.select.customer.label")}
                                            value={customerExternalId}
                                            onChange={handleChangeSelectedCustomer}
                                            select
                                            fullWidth
                                            style={{ maxWidth: 240 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <ClickAwayListener onClickAway={handleCopyTooltipClose}>
                                                        <Tooltip
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            onClose={handleCopyTooltipClose}
                                                            open={copyTooltipOpen}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            title={
                                                                copiedText
                                                                    ? `${copiedText} ${t(
                                                                          "button.copyCustomerId.tooltip.success",
                                                                      )}`
                                                                    : t("button.copyCustomerId.tooltip.error")
                                                            }
                                                            arrow
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                color="inherit"
                                                                edge="end"
                                                                style={{ marginTop: 2, fontSize: 20 }}
                                                                onClick={() => {
                                                                    copy(customerExternalId).then(() =>
                                                                        setCopyTooltipOpen(true),
                                                                    );
                                                                }}
                                                            >
                                                                <ContentCopy fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ClickAwayListener>
                                                ),
                                            }}
                                        >
                                            {linkedUserRequests?.userRequests?.map(({ externalCustomerID }) => (
                                                <MenuItem value={externalCustomerID} key={externalCustomerID}>
                                                    {externalCustomerID}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Fade>
                                ) : (
                                    <Skeleton variant="rectangular" width={240} height={48} />
                                )}
                            </div>
                            <CardContainer>
                                <CardColumn>
                                    <Card style={{ width: CARD_WIDTH, paddingTop: 32 }}>
                                        <CardHeader
                                            title={t("screen.userDetails.contact.title")}
                                            onEdit={handleClickEditPersonalData}
                                            editDisabled={!user?.customer?.optIn}
                                        />
                                        <CardDataContainer style={{ marginTop: 4 }}>
                                            <InfoBlock
                                                rows={[
                                                    {
                                                        label: t("screen.userDetails.information.name"),
                                                        value: userName,
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.companyName"),
                                                        value: user?.customer?.name,
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.corporatePosition"),
                                                        value: position ?? "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.region"),
                                                        value: user?.region?.description ?? "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.vtChannel"),
                                                        value: user?.externalVtwegID ?? "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.postCode"),
                                                        value: user?.customer?.postCode ?? "-",
                                                    },
                                                ]}
                                            />
                                        </CardDataContainer>
                                        <Divider style={{ marginTop: 4 }} />
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginTop: 4,
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Icon name="mail" style={{ color: Colors.PRIMARY_500 }} />
                                                <Link
                                                    href={userEmail ? `mailto:${userEmail}` : undefined}
                                                    underline="none"
                                                    style={{
                                                        marginLeft: 16,
                                                        pointerEvents: userEmail ? "auto" : "none",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            color: Colors.PRIMARY_500,
                                                            maxWidth: emailConfirmationPending ? 320 : 560,
                                                            wordBreak: "break-all",
                                                        }}
                                                    >
                                                        {userEmail ?? "-"}
                                                    </p>
                                                </Link>
                                            </div>

                                            {appUserProfile && userEmail && (
                                                <ConfirmationIcon
                                                    type="email"
                                                    confirmationPending={emailConfirmationPending}
                                                />
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Icon name="phone" />
                                                <Link
                                                    href={userPhone ? `tel:${userPhone}` : undefined}
                                                    underline="none"
                                                    style={{
                                                        marginLeft: 16,
                                                        pointerEvents: userPhone ? "auto" : "none",
                                                    }}
                                                >
                                                    <p style={{ color: Colors.PRIMARY_500 }}>{userPhone ?? "-"}</p>
                                                </Link>
                                            </div>
                                            {appUserProfile && userPhone && (
                                                <ConfirmationIcon
                                                    type="phone"
                                                    confirmationPending={phoneConfirmationPending}
                                                />
                                            )}
                                        </div>
                                    </Card>
                                    <Card style={{ width: CARD_WIDTH }}>
                                        <CardHeader title={t("screen.userDetails.information.title")} />
                                        <CardDataContainer>
                                            <InfoBlock
                                                rows={[
                                                    {
                                                        label: t("screen.userDetails.information.status_customer"),
                                                        value:
                                                            user.customerLockedStatus === "locked" ? (
                                                                <UserStatus status={"deactivated"} />
                                                            ) : (
                                                                <UserStatus status={"active"} />
                                                            ),
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.status"),
                                                        value: user?.status ? <UserStatus status={user.status} /> : "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.accountType"),
                                                        value: t(AccountTypes[user?.accountType]),
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.userProfile"),
                                                        value: userPreference?.name ?? "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.vko"),
                                                        value: getDistributorShortName(user?.distributorID) ?? "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.username"),
                                                        value: user?.alias ?? "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.userIdentifier"),
                                                        value: user?.userIdentifier ?? "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.source"),
                                                        value: user?.source
                                                            ? UserRequestSources[
                                                                  user.source as keyof UserRequestSourcesType
                                                              ]
                                                                ? t(
                                                                      UserRequestSources[
                                                                          user.source as keyof UserRequestSourcesType
                                                                      ],
                                                                  )
                                                                : user.source
                                                            : "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.createdAt"),
                                                        value: user?.createdAt
                                                            ? getFormattedDate(user?.createdAt, dateFormat)
                                                            : "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.createdBy"),
                                                        value: user?.createdBy
                                                            ? `${user.createdBy.firstName} ${user.createdBy.lastName}`
                                                            : "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.activeSince"),
                                                        value: user?.activeSince
                                                            ? getFormattedDate(user?.activeSince, dateFormat)
                                                            : "-",
                                                    },
                                                    {
                                                        label: t("screen.userDetails.information.lastActive"),
                                                        value: user?.appUserProfile?.lastActive
                                                            ? getFormattedDate(
                                                                  user?.appUserProfile?.lastActive,
                                                                  dateFormat,
                                                              )
                                                            : "-",
                                                    },
                                                ]}
                                            />

                                            <LinkButton
                                                color={Colors.PRIMARY_500}
                                                onClick={legalDocumentConsentsDialog.open}
                                            >
                                                {t("button.legalDocumentConsents")}
                                            </LinkButton>
                                        </CardDataContainer>

                                        <CardAction
                                            title={t("button.resetPassword")}
                                            onClick={resetPasswordDialog.open}
                                            disabled={user?.status !== "active"}
                                            dividerStyle={{ marginTop: 24 }}
                                        />

                                        <CardAction
                                            title={t(user?.status === "invited" ? "button.reinvite" : "button.invite")}
                                            tooltip={inviteTooltip}
                                            onClick={inviteDialog.open}
                                            disabled={!user?.canBeInvited || userManagementNoEmail}
                                        />

                                        {user?.canBeActivated && (
                                            <CardAction title={t("button.activate")} onClick={activateDialog.open} />
                                        )}
                                        {user?.canBeDeactivated && (
                                            <CardAction
                                                title={t("button.deactivate")}
                                                onClick={deactivateDialog.open}
                                            />
                                        )}

                                        <CardAction
                                            title={t("button.delete")}
                                            onClick={deleteDialog.open}
                                            color={Colors.RED}
                                            disabled={!user?.canBeDeleted}
                                            tooltip={!user?.canBeDeleted ? t("button.delete.tooltip.noPermission") : ""}
                                        />

                                        {!user?.customer?.optIn && (
                                            <CardAction
                                                title={t("button.forceUserManagement")}
                                                onClick={forceUserManagementDialog.open}
                                                color={Colors.RED}
                                            />
                                        )}
                                    </Card>
                                </CardColumn>
                                <CardColumn>
                                    <Card style={{ width: CARD_WIDTH, gap: 24 }}>
                                        <CardHeader title={t("screen.userDetails.role.title")} />
                                        {user.roles?.map((role) => (
                                            <React.Fragment key={role.id}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        gap: 8,
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        label={t("screen.userDetails.select.role.label")}
                                                        defaultValue={role.name}
                                                        onClick={() => handleClickEditRole(role.id)}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Icon name="chevronRight" />
                                                                </InputAdornment>
                                                            ),
                                                            readOnly: true,
                                                            style: {
                                                                cursor: "pointer",
                                                            },
                                                        }}
                                                        inputProps={{ style: { cursor: "pointer" } }}
                                                    />
                                                    <IconButton
                                                        onClick={() => handleClickDeleteRole(role.id)}
                                                        color="primary"
                                                    >
                                                        <Icon name="delete" useCurrentColor />
                                                    </IconButton>
                                                </div>
                                                <div style={{ overflowWrap: "anywhere" }}>{role.description}</div>
                                            </React.Fragment>
                                        ))}
                                        {user.roles?.length === 0 && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    gap: 8,
                                                }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label={t("screen.userDetails.select.role.label")}
                                                    defaultValue={t("screen.userDetails.select.role.noRole")}
                                                    onClick={handleClickAddRole}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Icon name="chevronRight" />
                                                            </InputAdornment>
                                                        ),
                                                        readOnly: true,
                                                        style: {
                                                            cursor: "pointer",
                                                        },
                                                    }}
                                                    inputProps={{ style: { cursor: "pointer" } }}
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <Divider />
                                            <LinkButton
                                                color={Colors.PRIMARY_500}
                                                onClick={handleClickAddRole}
                                                style={{ marginTop: 16 }}
                                            >
                                                {t("button.applyNewRole")}
                                            </LinkButton>
                                        </div>
                                    </Card>
                                    <Card style={{ width: CARD_WIDTH, gap: 24 }}>
                                        <CardHeader title={t("screen.userDetails.permission.title")} />
                                        <GrantedPermissionServicesList
                                            permissionServices={userPermissions.permissions}
                                            showDescriptions
                                            emptyMessage={t("screen.userDetails.noPermissions.text")}
                                        />
                                        <div>
                                            <Divider />
                                            <LinkButton
                                                color={Colors.PRIMARY_500}
                                                onClick={managePermissionsDialog.open}
                                                style={{ marginTop: 16 }}
                                            >
                                                {t("button.addPermissionServices")}
                                            </LinkButton>
                                            <Divider style={{ marginTop: 16 }} />
                                            <LinkButton
                                                color={Colors.PRIMARY_500}
                                                onClick={copyPermissionServicesDialog.open}
                                                style={{ marginTop: 16 }}
                                            >
                                                {t("button.copyPermissionServicesFromUser")}
                                            </LinkButton>
                                        </div>
                                    </Card>
                                </CardColumn>
                            </CardContainer>
                        </div>
                        {userDataEditDrawer.component}
                        {inviteDialog.component}
                        {activateDialog.component}
                        {deactivateDialog.component}
                        {deleteDialog.component}
                        {forceUserManagementDialog.component}
                        {legalDocumentConsentsDialog.component}
                        {copyPermissionServicesDialog.component}
                        {editRoleDrawer.component}
                        {managePermissionsDialog.component}
                        {resetPasswordDialog.component}
                    </div>
                </Fade>
            );
        }
    },
);
