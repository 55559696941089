import { IconButton, Tabs, Tab } from "@mui/material";
import React, { useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../util/Colors";
import { DEFAULT_TAB_ID } from "../users/sites/UsersSite";
import { Icon } from "../util/Icon";
import { ITab } from "../../types";

const CustomTabLabel = ({
    tab,
    index,
    activeTab,
    onCloseTab,
}: {
    tab: ITab;
    index: number;
    activeTab: string | undefined;
    onCloseTab: (id: string) => void;
}) => {
    return (
        <span
            style={{
                display: "flex",
                alignItems: "center",
                fontSize: 14,
                minHeight: 48,
            }}
        >
            <span
                style={{
                    marginLeft: 4,
                    marginRight: 4,
                    maxWidth: 200,
                    minWidth: 150,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }}
            >
                {tab.values.length > 0 ? (
                    tab.values.map((value, i) => (
                        <p key={`${value}_${i}`} style={{ fontSize: "inherit", fontWeight: 600 }}>
                            {value}
                        </p>
                    ))
                ) : (
                    <Icon name="warning" size={40} color={activeTab === tab.id ? Colors.GREY_700 : Colors.GREY_500} />
                )}
            </span>

            {index > 0 && (
                <IconButton
                    size="small"
                    style={{
                        padding: 0,
                        marginLeft: 8,
                        border: `2px solid ${activeTab === tab.id ? Colors.PRIMARY_500 : Colors.GREY_500}`,
                        color: `${activeTab === tab.id ? Colors.PRIMARY_500 : Colors.GREY_500}`,
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        onCloseTab(tab.id);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            )}
        </span>
    );
};

export const TabNavigation = (props: {
    tabs: ITab[];
    defaultTab: ITab;
    activeTab: string | undefined;
    onChange: (event: React.SyntheticEvent, valueId: string) => void;
    onCloseTab: (id: string) => void;
    style?: React.CSSProperties;
}) => {
    const tabsRef = useRef<any>(null);

    useEffect(() => {
        const scrollerElement = tabsRef.current?.querySelector(".MuiTabs-scroller"); // workaround since older Mui Version do not support slotProps to directly ref the scroller element

        if (!scrollerElement) return;

        const handleWheel = (event: WheelEvent) => {
            event.preventDefault();
            scrollerElement.scrollLeft += event.deltaY;
        };

        scrollerElement.addEventListener("wheel", handleWheel);

        return () => {
            scrollerElement.removeEventListener("wheel", handleWheel);
        };
    }, []);

    return (
        <Tabs
            ref={tabsRef}
            style={props.style}
            value={props.activeTab === undefined ? DEFAULT_TAB_ID : props.activeTab}
            onChange={props.onChange}
            variant="scrollable"
        >
            {[props.defaultTab, ...props.tabs].map((tab, index) => {
                return (
                    <Tab
                        disableRipple
                        style={{
                            borderRight: `1px solid ${Colors.GREY_200}`,
                        }}
                        key={tab.id}
                        label={
                            <CustomTabLabel
                                tab={tab}
                                index={index}
                                activeTab={props.activeTab}
                                onCloseTab={props.onCloseTab}
                            />
                        }
                        value={tab.id}
                        component="span" //prevent React Dom Error
                    />
                );
            })}
        </Tabs>
    );
};
