import { useState } from "react";
import { AddPartnerPortalUserForm } from "../components/forms/addUser/AddPartnerPortalUserForm";
import { AddUserForm } from "../components/forms/addUser/AddUserForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { DialogHeader } from "../components/ui/DialogHeader";
import { t } from "../i18n/util";
import { AccountType } from "../network/APITypes";
import { AccountTypeSelection } from "../components/forms/addUser/AccountTypeSelection";
import { getIsPartnerAccountType } from "../components/util/Helpers";

export const useAddUserForm = ({ onSubmit }: { onSubmit?: () => void | Promise<void> }) => {
    const [accountType, setAccountType] = useState<AccountType>("b2b-customer");
    const [open, setOpen] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const reset = () => {
        setAccountType("b2b-customer");
        setStep(0);
    };

    const isPartner = getIsPartnerAccountType(accountType);

    const component = (
        <CustomDrawer
            onClose={handleClose}
            open={open}
            style={{ display: "flex", flexDirection: "column", height: "100%", padding: "16px 12px" }}
        >
            <DialogHeader
                onBack={step > 0 ? () => setStep((prev) => prev - 1) : undefined}
                onClose={handleClose}
                title={
                    step > 0
                        ? isPartner
                            ? t("fbp.corporate_context.partner.platform.label")
                            : t("fbp.corporate_context.customer.platform.label")
                        : `${t("form.step")} ${step + 1}: ${t("common.accountType.select")}`
                }
                style={{ marginBottom: 12 }}
            />
            {step === 0 ? (
                <AccountTypeSelection
                    value={accountType}
                    onChange={(event) => setAccountType(event.target.value as AccountType)}
                    onClickNext={() => setStep(1)}
                />
            ) : (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                    }}
                >
                    {isPartner ? (
                        <AddPartnerPortalUserForm
                            accountType={accountType}
                            onClose={handleClose}
                            onSubmit={onSubmit}
                            step={step}
                            setStep={setStep}
                        />
                    ) : (
                        <AddUserForm
                            accountType={accountType}
                            key={accountType}
                            onClose={handleClose}
                            onSubmit={onSubmit}
                            step={step}
                            setStep={setStep}
                        />
                    )}
                </div>
            )}
        </CustomDrawer>
    );

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
