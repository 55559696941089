import { useEffect, useState } from "react";

type OciResponse = { parameters: Record<string, string> };
export const useOCIResponseMessageListener = () => {
    const [hookResponse, setHookResponse] = useState<OciResponse | null>(null);

    useEffect(() => {
        // Listen for incoming form submissions
        const handleFormResponse = (event: MessageEvent) => {
            if (event.data && event.data.type === "oci-hook-response") {
                const formData = new FormData(event.data.form);
                const response: Record<string, string> = {};
                formData.forEach((value, key) => {
                    response[key] = value.toString();
                });

                setHookResponse({
                    parameters: { ...response },
                });
            }
        };

        window.addEventListener("message", handleFormResponse);

        const responseFrame = document.createElement("iframe");
        responseFrame.name = "oci-response-frame";
        responseFrame.style.display = "none";
        document.body.appendChild(responseFrame);

        responseFrame.addEventListener("load", () => {
            try {
                const form = responseFrame.contentDocument?.querySelector("form");
                if (form) {
                    window.postMessage(
                        {
                            type: "oci-hook-response",
                            form,
                        },
                        "*",
                    );
                }
            } catch (e) {
                console.error("Error processing hook response:", e);
            }
        });

        return () => {
            window.removeEventListener("message", handleFormResponse);
            document.body.removeChild(responseFrame);
        };
    }, []);

    return hookResponse;
};
